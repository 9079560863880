import axios from "axios";

const headers = {
	"Content-Type": "application/json",
	Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
};

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers,
});

axiosInstance.interceptors.request.use((request) => {
	return request;
});

export default axiosInstance;
