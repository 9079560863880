import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Fade from "react-reveal/Fade";

function TransSuccess({ redirectUrl }) {
	useEffect(() => {
		setTimeout(() => {
			window.location = redirectUrl;
		}, 3000);
	}, []);

	return (
		<Fade bottom>
			<div className="w-full">
				<div className="flex flex-col items-center">
					<img
						src="/assets/icons/check.png"
						alt="check"
						className="object-contain pry-clr h-[70px] w-[70px]"
					/>
					<p className="font-medium pry-clr text-lg mt-3 mb-2">
						Transaction Successful!
					</p>

					{/* <p className="text-[15px] text-black text-opacity-60 mb-3 whitespace-nowrap">
						We sent your receipt to{" "}
						{mainTransactionInfo?.customerEmail || "tech@blinqpay.io"}
					</p> */}

					<p className="text-center pry-clr tracking-wide mb-2">
						You will be redirected shortly
						<br />
						...
					</p>
				</div>
			</div>
		</Fade>
	);
}

export default TransSuccess;
