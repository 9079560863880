import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axios/axiosInterceptors";

const initialState = {
	mainLoading: true,
	isUserRedirecting: false,
	mainTransactionInfo: null,
	cardData: null,
	transactionRef: "",
	errorMsg: null,
};

export const getTransactionStatus = createAsyncThunk(
	"global/getStatus",
	async (reference) => {
		const { data } = await axiosInstance.get(`get-init-trans/${reference}`);
		return data?.data;
	}
);

export const globalSlice = createSlice({
	name: "global",
	initialState,
	reducers: {
		beginTransaction: (state, { payload }) => {
			return {
				...state,
				loading: false,
				transactionRef: payload,
			};
		},

		endTransaction: (state) => {
			return {
				...state,
				errorMsg: "Something went wrong. Please retry",
			};
		},

		redirectUser: (state) => {
			return {
				...state,
				loading: true,
				isUserRedirecting: true,
			};
		},

		updateCardData: (state, { payload }) => {
			return {
				...state,
				cardData: payload,
			};
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getTransactionStatus.fulfilled, (state, action) => {
			state.mainLoading = false;
			state.mainTransactionInfo = action.payload;
		});

		builder.addCase(getTransactionStatus.rejected, (state, action) => {
			state.mainLoading = false;
			state.mainTransactionInfo = null;
			state.errorMsg = "Something went wrong. Please retry";
		});
	},
});

export const {
	beginTransaction,
	endTransaction,
	redirectUser,
	updateCardData,
} = globalSlice.actions;

export default globalSlice.reducer;
