import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PayStudio from "./pages/PayStudio";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<PayStudio />} />
				<Route path="/*" element={<PayStudio />} />
			</Routes>
		</Router>
	);
}

export default App;
