import { useState } from "react";
import axiosInstance from "../../axios/axiosInterceptors";

function useVerifyRef() {
	const [verifyLoading, setVerifyLoading] = useState(true);
	const [verifyData, setVerifyData] = useState([]);
	const [verifyError, setVerifyError] = useState(null);
	// For submitting transactions
	const [submitResponse, setSubmitResponse] = useState(null);

	const verifyTransaction = async (reference) => {
		setVerifyLoading(true);
		setVerifyError(null);

		try {
			const { data } = await axiosInstance.get(
				`get-high-transaction-details/${reference}`
			);

			const response = data.data;

			setVerifyData(response);
			setVerifyLoading(false);
		} catch (error) {
			const message = error?.response?.data?.message;

			setVerifyError(message);
			setVerifyLoading(false);
		}
	};

	const submitTransaction = async (payload) => {
		setVerifyLoading(true);
		setVerifyError(null);

		try {
			const { data } = await axiosInstance.post(
				`high-update-onetime-transaction`,
				payload
			);

			const response = data.data;
			setSubmitResponse(response);
			setVerifyLoading(false);

			window.location.replace(data.data.redirectUrl);
		} catch (error) {
			const message = error?.response?.data?.message;

			setVerifyError(message);
			setVerifyLoading(false);
		}
	};

	return {
		verifyLoading,
		setVerifyLoading,
		verifyData,
		verifyError,
		setVerifyError,
		verifyTransaction,
		submitTransaction,
		submitResponse,
		setSubmitResponse,
	};
}

export default useVerifyRef;
