function BlinqButton({ text, light, onClick, small, fitted, type, disabled }) {
	return (
		<div className="my-1  w-full flex items-center justify-center">
			<button
				disabled={disabled}
				type={type}
				onClick={onClick}
				className={`whitespace-nowrap ${
					light
						? "bg-transparent pry-clr border border-[#7765c4] text-[#7765c4] hover:bg-[#7765c4] hover:text-white"
						: "bg-[#7765c4] text-center text-white"
				}  px-8 py-2 text-sm font-medium tracking-wide rounded-md hover:brightness-125 transition-all cursor-pointer ${
					small ? "w-[40%]" : fitted ? "w-full" : "w-10/12"
				}`}>
				{text}
			</button>
		</div>
	);
}

export default BlinqButton;
