import React, { useEffect, useState } from "react";
import useVerifyRef from "../utils/hooks/paystudio/useVerifyRef";
import BlinqButton from "../utils/misc/BlinqButton";
import BlinqLoader from "../utils/misc/BlinqLoader";
import MethodInterface from "../utils/misc/MethodInterface";
import TransError from "../utils/trans/TransError";
import TransSuccess from "../utils/trans/TransSuccess";

function PayStudio() {
	const [transValue, setTransValue] = useState(null);
	const [transError, setTransError] = useState(null);

	const {
		verifyLoading,
		setVerifyLoading,
		verifyData,
		verifyError,
		setVerifyError,
		verifyTransaction,
		submitTransaction,
		submitResponse,
		setSubmitResponse,
	} = useVerifyRef();

	const cancelTransaction = () => {
		setVerifyLoading(true);
		window.location = verifyData?.callbackUrl || "https://blinqpay.io";
	};

	const isSuccessful = submitResponse?.tranactionStatus === "success";
	const isFailed = submitResponse?.tranactionStatus === "failed";

	const reference =
		window.location.pathname.substring(1) || "BLQTEST-202231314631934830162";

	const submit = () => {
		if (!transValue) {
			setTransError("Select a response first!");
		} else if (transValue === "success") {
			setTransError(null);
			const payload = {
				transaction_status: "success",
				transactionreference: reference,
				reason: "Your transaction has been processed successfully",
			};

			submitTransaction(payload);
		} else if (transValue === "Insufficient Funds") {
			setTransError(null);
			const payload = {
				transaction_status: "failed",
				transactionreference: reference,
				reason: "Insufficient Funds",
			};

			submitTransaction(payload);
		} else if (transValue === "failed") {
			setTransError(null);
			const payload = {
				transaction_status: "failed",
				transactionreference: reference,
				reason: "Faiiled 3D Authentication",
			};

			submitTransaction(payload);
		}
	};

	function validate() {
		if (!reference || reference.length < 7) {
			setVerifyError("Invalid Transaction Reference");
			setVerifyLoading(false);
		} else {
			verifyTransaction(reference);
		}
	}

	useEffect(() => {
		validate();
	}, [reference]);

	return (
		<MethodInterface isTransparent isPaystudio onModalClose={cancelTransaction}>
			{verifyLoading ? (
				<div className="flexed">
					<BlinqLoader />
				</div>
			) : verifyError ? (
				<div className="flexed">
					<TransError text={verifyError} onRetry={validate} />
				</div>
			) : isSuccessful ? (
				<TransSuccess redirectUrl={submitResponse?.callbackUrl} />
			) : isFailed ? (
				<TransError
					text="Transaction declined due to Insufficient Funds or Failed Authentication"
					onRetry={() => {
						setSubmitResponse(null);
					}}
				/>
			) : (
				<>
					<div className="">
						<img
							src="/blinqLogo.png"
							width={30}
							alt="blinqpay-logo"
							className="mb-3"
						/>
					</div>
					<div className="text-center h-full">
						<p className="font-medium tracking-wide">
							Amount:
							<span className="font-bol2 ml-3">
								{parseFloat(verifyData?.amount).toFixed(2) || ""} USD
							</span>
						</p>
						<p className="my-3 tracking-wide">Select a response:</p>

						<select
							onChange={(e) => {
								const option = e.target.value;
								setTransValue(option);
								setTransError(null);
							}}
							className="py-2 px-4 rounded-md shadow-lg border border-purple-600  transition-all cursor-pointer outline-none">
							<option value="">-- Response --</option>
							<option value="success" reason="your success">
								Approved
							</option>
							<option value="Insufficient Funds">Insufficient Funds</option>
							<option value="failed">Failed 3D Authentication</option>
						</select>
						<p className="text-red-500 mb-7 mt-1.5">{transError || ""}</p>

						<BlinqButton text="Continue" onClick={submit} />

						<p
							className="mt-3 text-underline pry-clr text-sm underline cursor-pointer"
							onClick={cancelTransaction}>
							Cancel Transaction
						</p>
					</div>
				</>
			)}
		</MethodInterface>
	);
}

export default PayStudio;
