import React from "react";
import BlinqButton from "../misc/BlinqButton";

function TransError({ onRetry, text, btnText }) {
	return (
		<div className="flex flex-col items-center pt-4">
			<img
				src="/assets/icons/error.png"
				alt="check"
				className="object-contain pry-clr h-[70px] w-[70px]"
			/>
			<p className="font-medium  text-black text-center lg:text-lg mt-3 mb-4">
				{text || "Your Payment could not be confirmed"}
			</p>
			<BlinqButton light text={btnText || "Retry"} onClick={onRetry} />
		</div>
	);
}

export default TransError;
