import React from "react";

function BlinqLoader() {
	return (
		<div className="w-full my-5 flex flex-col items-center justify-center">
			<img
				className="animate-bounce w-9 h-9 object-contain mb-1"
				src="/assets/icons/blinqLogo.png"
				alt="logo"
			/>

			<p className="pry-clr text-sm font-medium tracking-wide">
				Please wait...
			</p>
		</div>
	);
}

export default BlinqLoader;
