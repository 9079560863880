import React from "react";

function Watermark() {
	return (
		!window.location.href.includes("/cards/charge-card") && (
			<div className="flex flex-col justify-center items-center">
				<p className="text-black font-medium mt-2 flex items-center justify-center">
					<span className="">Powered by </span>
					<img
						src="/assets/icons/blinqLogo.png"
						alt="blinqIcon"
						className="mx-1 h-5 w-5"
					/>
					Blinqpay
				</p>
			</div>
		)
	);
}

export default Watermark;
