import React from "react";
import { MdClose } from "react-icons/md";
import Watermark from "./Watermark";

function MethodInterface({
	isTransparent,
	children,
	onModalClose,
	isPaystudio,
}) {
	return (
		<div className="flex flex-col items-center justify-center bg-[#F9F9F9] min-h-screen px-3">
			<div
				className={`bg-[#fafafa] relative flex items-center justify-center w-full min-h-[250px] md:w-2/5 lg:w-[35%] rounded-lg shadow-lg ${
					isPaystudio && "lg:w-1/4 mx-auto mt-3"
				}`}>
				{/* <span
					onClick={onModalClose}
					className="absolute border -right-7 -top-4 h-7 w-7 rounded-full flexed bg-[#7765c4] text-white cursor-pointer hover:scale-110 transition-all point">
					<MdClose size="1.4rem" />
				</span> */}
				<div
					className={`py-5 px-2 md:px-8 w-full relative ${
						isTransparent
							? "bg-transparent"
							: "bg-[#f0f1f2] rounded-md shadow-lg"
					}`}>
					{children}
				</div>
			</div>
			<Watermark />
		</div>
	);
}

export default MethodInterface;
